@use 'sass:map';
@use 'sass:math';

@use '@shared/styles/config/variables' as *;
@use '@shared/styles/mixins/breakpoint' as *;

.root {
  ul {
    display: flex;
    flex-wrap: wrap;
    margin: - map.get($spacers, 'spacing', '2');

    @include breakpoint('md', 'min') {
      margin: - map.get($spacers, 'spacing', '3');
    }

    @include breakpoint('lg', 'min') {
      margin: - map.get($spacers, 'spacing', '4');
    }
  }

  li {
    display: flex;
    padding: map.get($spacers, 'spacing', '2');

    @include breakpoint('md', 'min') {
      padding: map.get($spacers, 'spacing', '3');
    }

    @include breakpoint('lg', 'min') {
      padding: map.get($spacers, 'spacing', '4');
    }
  }
}

.small {
  .logo {
    width: 5.625rem;

    @include breakpoint('md', 'min') {
      width: 7.75rem;
    }

    @include breakpoint('lg', 'min') {
      width: 8.5rem;
    }

    @include breakpoint('xl', 'min') {
      width: 11.125rem;
    }
  }
}

.medium {
  .logo {
    width: 5.625rem;

    @include breakpoint('md', 'min') {
      width: 7.75rem;
    }

    @include breakpoint('lg', 'min') {
      width: 10.25rem;
    }
  }
}

.left {
  ul {
    justify-content: flex-start;
  }
}

.center {
  ul {
    justify-content: center;
  }
}

.grayscale {
  img {
    filter: grayscale(1);
  }
}

.logo {
  border: map.get($borders, '1') solid transparent;
  background-color: map.get($colors, 'white');
  border-radius: map.get($radiuses, '2');
  display: block;
  padding: map.get($spacers, 'spacing', '2')
    math.div(map.get($spacers, 'spacing', '6'), 2);

  @include breakpoint('md', 'min') {
    border-radius: map.get($radiuses, '3');
    padding: map.get($spacers, 'spacing', '3') map.get($spacers, 'spacing', '6');
  }

  img {
    height: 2rem;
    object-fit: contain;
    width: 100%;

    @include breakpoint('md', 'min') {
      height: 3rem;
    }

    @include breakpoint('lg', 'min') {
      height: 4rem;
    }
  }
}

.size-large {
  ul {
    gap: 0.25rem 2.25rem;

    li {
      padding: 0;

      .logo {
        padding: 0;
        width: auto;
        padding: 0;

        img {
          width: fit-content;
          height: 3.5rem;

          @include breakpoint('sm', 'min') {
            height: 5rem;
          }

          @include breakpoint('md', 'min') {
            height: 6rem;
          }
        }
      }
    }
  }
}

.logoWithLink {
  &:hover {
    box-shadow: map.get($shadows, '1');
    border: map.get($borders, '1') solid map.get($colors, 'blackbird-500');
  }
}